import React, { useState } from "react";
import { Formik, Form as FormikForm, FormikProps, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { User, useRegisterMutation, useUpdateUserMutation } from "../../services/endpoints/people/user";
import { Button, Image, Stack } from "react-bootstrap";
import { FadeIn } from '../animations/FadeIn';
import { SimpleButton } from '../../components/buttons/SimpleButton';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Stepper from '../../components/stepper/Stepper';
import { FaArrowLeft } from "react-icons/fa";
import { BasicInfoForm } from './RegisterFormSteps/BasicInfoForm';
import { LocationForm } from './RegisterFormSteps/LocationForm';
import { FinalizeForm } from './RegisterFormSteps/FinalizeForm';
import { phoneRegExp } from '../../utils/consts';
import SFCLogo from '../../assets/images/sticky-fingers-logo-stacked-2.png';
import { getErrorMessage, getObjectDifference } from "../../utils/utils";
import { getCurrentUser } from '../../services/helper';

const steps = [
  { name: 'Basic info', form: BasicInfoForm },
  { name: 'Location', form: LocationForm },
];

export interface FormValues {
  id?: number;
  email: string;
  password?: string;
  confirm_password?: string;
  first_name: string;
  last_name: string;
  phone_mobile: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  timezone?: string;
  heard_about?: string;
}
export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
}

const validationSchemas = [
  Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_mobile: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'Phone number should have at least 10 digits')
      .required('Phone number is required'),
    email: Yup.string().email("This is not a valid email.").required('Email address is required'),
  }),
  Yup.object({
    address: Yup.string().optional(),
    address2: Yup.string().optional(),
    city: Yup.string().optional(),
    state: Yup.string().optional(),
    zip: Yup.string().optional(),
    timezone: Yup.string().required('Preferred time zone is required'),
  }),
  Yup.object({
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
    heard_about: Yup.string().optional(),
  }),
];

const Register: React.FC<{hide?: (success: boolean) => void, user?: User}> = (props) => {
  const isEditing = !!props.user;
  if (!isEditing) {
    steps[2] = { name: 'Finalize', form: FinalizeForm }
  }
  
  const navigate: NavigateFunction = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [register] = useRegisterMutation();
  const [updateUser] = useUpdateUserMutation();
  
  if (props.user?.address2 === null) {
    props.user.address2 = '';
  }

  const initialValues: FormValues | User = props.user ?? {
    email: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    phone_mobile: '',
    timezone: '',
    address: '',
  };


  const handleRegister = async (formValue: FormValues) => {
    delete formValue.confirm_password;
    
    try {
      if (props.user) {
        let user = getCurrentUser();
        if (user === null || user?.id === undefined) {
          return;
        }
        let update = getObjectDifference(props.user, formValue);
        let submitUpdate = Object.keys(update).length > 0;
        if (submitUpdate) {
          await updateUser(update).unwrap();
        }
        setSubmitted(submitUpdate);
      }
      else {
       
        await register(formValue).unwrap();
        setSubmitted(true);
      }
      setSuccessful(true);
      setCurrentStep(currentStep + 1);
    } catch (error) {
      setMessage(getErrorMessage(error));
      setSuccessful(false);
    }
  };

  
  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>,
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        email: true,
        password: true,
        confirm_password: true,
        first_name: true,
        last_name: true,
        phone_mobile: true,
        address: true,
        address2: true,
        city: true,
        state: true,
        zip: true,
        timezone: true,
      });
    }
  };

  const handlePrev = () => {
    setSuccessful(false);
    setSubmitted(false);
    setMessage('');
    setCurrentStep((prevStep: number) => prevStep > 0 ? prevStep - 1 : prevStep);
  };

  return (
    <FadeIn className="d-flex flex-column flex-sm-row justify-content-center pt-3">
      <FaArrowLeft
        onClick={() => handlePrev()}
        className={`text-info mb-2 my-sm-2 me-2 ${currentStep === 0 || (currentStep === steps.length && submitted) ? 'opacity-0' : ''}`}
        style={{ fontSize: 22, cursor: 'pointer', transition: 'opacity ease-in 0.2s' }}
      />

      <div className="w-100" style={{ maxWidth: 560 }}>
        <h2 className="text-info fw-bold mb-4 mb-lg-5 text-start" style={{ fontSize: 32 }}>
          {isEditing ? 'Edit Profile' : successful ? 'Welcome User!' : 'Create an Account'}
        </h2>

        <Stepper steps={steps} currentStep={currentStep} editing={isEditing === true} onStepClick={(step) => setCurrentStep(step)} />

        {successful ? (
          <div className="text-secondary text-center" style={{ minHeight: 'calc(20vh + 220px)' }}>
            <Image src={SFCLogo} className="d-inline d-lg-none my-5" alt="Sticky Fingers Logo" style={{ maxWidth: 225 }} />
            {/* <Image src={FemaleChef} className="d-none d-lg-inline my-5" alt="Female chef cooking" width={300} /> */}
            <h3 className="fw-bold">{submitted ? `Your account has 🫘 BEAN ${isEditing ? 'updated' : 'created'}` : 'No changes detected!'}</h3>
            {!isEditing && (
              <>
                <p className="fw-semibold mb-0" style={{ fontSize: '1.125rem' }}>
                  Go to My Account to update your information, add your child(ren) to your account, and start registering for sessions!
                </p>
                <button
                  onClick={() => window.location.href = '/children/new'}
                  className="btn btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
                >
                  + Add Child
                </button>
              </>
            )}
            {isEditing && submitted && (<Button onClick={() => props.hide?.(true)}>Done</Button>)}
          </div>
        ) : (
          <Stack gap={1}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleRegister}
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched }: FormikProps<FormValues>) => (
                <FormikForm className="text-start mt-3">
                  <div style={{ minHeight: 'calc(20vh + 220px)' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched
                      })
                    }
                  </div>

                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}

                  <SimpleButton
                    disabled={isSubmitting}
                    type="button"
                    onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched)}
                    className="mt-3 w-100"
                    variant="primary"
                  >
                    {currentStep === steps.length - 1 ? (isEditing ? 'Update profile' : 'Create account') : 'Continue'}
                  </SimpleButton>
                </FormikForm>
              )}
            </Formik>

            {!isEditing && (
              <div className="my-3 d-flex justify-content-center align-items-center">
                <span className="ff-inter fs-small text-secondary">Already have an account?</span>
                <button
                  onClick={() => navigate('/users/login')}
                  className="btn btn-link fs-small fw-bolder text-uppercase link-primary link-underline-opacity-0"
                >
                  Sign In
                </button>
              </div>
            )}
          </Stack>
        )}

        
      </div>
    </FadeIn>
  );
};

export default Register;
