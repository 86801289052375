import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { getCurrentUser } from '../../../../../services/helper';
import { useDataContext } from '../../../../../context';
import PowerBIReports from '../../../../../services/endpoints/powerBi/PowerBIReports';
import { PowerBi, useGetPowerBiTokensQuery } from '../../../../../services/endpoints/powerBi/powerBiToken';

const defaultScope = 'all';
const currentUser = getCurrentUser();

export const PowerBiView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const [powerBiToken, setPowerBiToken] = useState<PowerBi[]>();
  const [accsessToken, setAccsessToken] = useState<string>();
  const [embedToken, setEmbedToken] = useState<string>();
  const isGlobalUser = (currentUser?.isGlobal);
  const userCanSeeFinancials = (currentUser?.canSeeFinancials);
  const { data, error, isLoading } = useGetPowerBiTokensQuery();




  useEffect(() => {
    if (data && data?.results) {
      const embedToken=Object.entries(data?.results)[1][1];
      const accessToken=Object.entries(data?.results)[0][1];
      setEmbedToken(embedToken)
      setAccsessToken(accessToken);
     

      setPowerBiToken(data?.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      {accsessToken && embedToken && (
        <PowerBIReports
        accessToken={accsessToken}
        embedToken={embedToken}
        workspaceId="284d2810-3955-44c6-8ef4-1913cb4a3c01"
        reportId="d6ebb764-0125-4139-87c5-b22c63bb5b96"
          />
      )

      }
     
    </div>
  );
}

