import { Card, Col, Row } from "react-bootstrap";
import { BsClock, BsFiletypePdf } from "react-icons/bs";
import LineDiciplineImg from "../../assets/images/line-of-diciplines.png";
import { Order } from "../../types/orders.type";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GoButton } from "../../components/buttons/GoButton";
import { formatTwoDates } from "../../utils/utils";
import { Course } from "../../types/course.type";
import AddFavorite from "../home/search/details/AddFavorite";

interface SessionCardProps {
  course: Course;
  edit: () => void;
  isFavoritePage?: boolean;
  order?: Order;
  updateFavorites?: () => void;
}

const SessionCard = ({
  order,
  course,
  edit,
  isFavoritePage,
  updateFavorites,
}: SessionCardProps) => {
  return (
    <Card className="text-left shadow mt-4 mb-4">
      <Card.Body>
        <Row className={"mb-3"}>
          <Col sm={6}>
            <Card.Img
              src={LineDiciplineImg}
              style={{ height: "48px", width: "276px" }}
            />
          </Col>
          {order && (
            <Col
              sm={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Card.Text
                className="mb-0 me-2 text-secondary"
                style={{ fontWeight: 600 }}
              >
                Order #{order.id}
              </Card.Text>
              <button
                onClick={edit}
                className="btn btn-sm btn-primary fw-bolder text-uppercase"
              >
                <BsFiletypePdf /> View Receipt
              </button>
            </Col>
          )}
          {isFavoritePage && (
            <Col
              sm={6}
              className="d-flex align-items-center justify-content-end"
            >
              <AddFavorite
                show_label={false}
                course_id={course.id}
                updateFavorites={updateFavorites}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={12}>
            <Card.Text className={"h2 text-capitalize text-info fw-bold"}>
              {course.title}
            </Card.Text>
          </Col>
        </Row>
        <Row className={"mb-3"}>
          <Col sm={12}>
            <Card.Text className={"h4 text-capitalize text-secondary fw-bold"}>
              {formatTwoDates(course.starts_on, course.ends_on)}
            </Card.Text>
          </Col>
        </Row>
        <Card.Subtitle className="text-muted">
          {!course.is_online ? "In-Person" : "Online"}
        </Card.Subtitle>
        <Card.Text className="text-info fw-bold">
          <FaMapMarkerAlt />{" "}
          {course.venue
            ? course.venue.location.business_entity_name ??
              course.venue.location.title
            : ""}
        </Card.Text>
        <Card.Subtitle className="text-muted">Time</Card.Subtitle>
        <Card.Text>
          <BsClock /> {course.time}
        </Card.Text>
        <Card.Subtitle>
          <GoButton text="View Full Session Details" url={`/sessions/${course.id}`} />
        </Card.Subtitle>
        {!isFavoritePage && (
          <Card.Subtitle>
            <GoButton text="View Class Details & Recipes" url={`/user/class-details-recipes/${course.starts_on}`}/>
          </Card.Subtitle>
        )}
      </Card.Body>
    </Card>
  );
};

export default SessionCard;
