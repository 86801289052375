import { GetQueryParams, GetQueryParamsWithFilterArray, GetQueryResponse } from '../../../types/api.type';
import { Recipe } from '../../../types/lessonPlan.type';
import { sfcApi } from '../../api';

export interface AddRecipeFormValues {
  id?: number;
  title: string;
  author?: string;
  publish_status?: string;
  recipe_type: string;
  story?: string;
  kitchen_skill_ids: number[];
  featured_ingredient_id?: number;
  food_history_id?: number;
  country_fact_id?: number;
  season?: string;
  cuisine_type?: string;
  meal_type?: string;
  cook_method?: string;
  image_metadata: {
    id?: number;
    source?: string;
    destroy: boolean;
  }[];
  cook_time?: number;
  prep_time?: number;
  min_served?: number;
  max_served?: number;
  ingredients?: string;
  substitutions?: string;
  recipe_joke_ids: number[];
  parent_equipments?: string;
  instructor_equipments?: string;
  recipe_steps_parent?: string;
  recipe_steps_instructor?: string;
  public_file?: File;
  private_file?: File;
  images: File[];
}

export const recipeApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipes: builder.query<GetQueryResponse, GetQueryParamsWithFilterArray | void>({
      query: (params) => ({
        url: `recipes${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Recipe'],
    }),
    getRecipe: builder.query<{ recipe: Recipe }, number>({
      query: (id) => `recipes/${id}`,
    }),
    createRecipe: builder.mutation<{ recipe: Recipe }, AddRecipeFormValues>({
      query: (data) => {
        const formData = new FormData();

        let payload: { url: string; method: string; body: AddRecipeFormValues | FormData; formData?: boolean } = {
          url: 'recipes/create',
          method: 'POST',
          body: data,  
        };

        Object.entries(data).forEach(([key, value]) => {
          if (key !== 'images' && key !== 'image_metadata' && key !== 'public_file' && key !== 'private_file') {
            if (typeof value === 'object' && !Array.isArray(value)) {
              formData.append(key, JSON.stringify(value));
            } else if (Array.isArray(value)) {
              value.forEach((v, index) => {
                formData.append(`${key}[${index}]`, v);
              });
            } else {
              formData.append(key, value as string | Blob);
            }
          }
        });
        
        if (data.image_metadata?.length || data.public_file || data.private_file) {
          if (data.image_metadata?.length) {
            data.image_metadata.forEach((metadata, index) => {
              let image = data.images?.[index];
              const source = metadata.source ? metadata.source : (image ? image.name : 'empty');
              formData.append(`image_metadata[${index}][source]`, String(source));
              if (metadata.id) {
                formData.append(`image_metadata[${index}][id]`, String(metadata.id));
              }
              formData.append(`image_metadata[${index}][destroy]`, String(metadata.destroy ?? false));
              if (!image) {
                image = new File([], "empty.png")
              }
              formData.append(`images`, image);
            });
          }

          if (data.public_file) {
            formData.append('public_file', data.public_file);
          }

          if (data.private_file) {
            formData.append('private_file', data.private_file);
          }

          payload.formData = true;
          payload.body = formData;
        }
        return payload;
      },
      invalidatesTags: ['Recipe'],
    }),
    updateRecipe: builder.mutation<{ recipe: Recipe }, AddRecipeFormValues>({
      query: (data) => {
        const formData = new FormData();
        const { id } = data;
        delete data.id;
        let payload: { url: string; method: string; body: AddRecipeFormValues | FormData; formData?: boolean } = {
          url: `recipes/update/${id}`,
          method: 'PUT',
          body: data,
        };

        Object.entries(data).forEach(([key, value]) => {
          if (key !== 'images' && key !== 'image_metadata' && key !== 'public_file' && key !== 'private_file') {
            if (typeof value === 'object' && !Array.isArray(value)) {
              formData.append(key, JSON.stringify(value));
            } else if (Array.isArray(value)) {
              value.forEach((v, index) => {
                formData.append(`${key}[${index}]`, v);
              });
            } else {
              formData.append(key, value as string | Blob);
            }
          }
        });
        
        if (data.image_metadata?.length || data.public_file || data.private_file) {
          if (data.image_metadata?.length) {
            data.image_metadata.forEach((metadata, index) => {
              let image = data.images?.[index];
              const source = metadata.source ? metadata.source : (image ? image.name : 'empty');
              formData.append(`image_metadata[${index}][source]`, String(source));
              if (metadata.id) {
                formData.append(`image_metadata[${index}][id]`, String(metadata.id));
              }
              formData.append(`image_metadata[${index}][destroy]`, String(metadata.destroy ?? false));
              if (!image) {
                image = new File([], "empty.png")
              }
              formData.append(`images`, image);
            });
          }

          if (data.public_file) {
            formData.append('public_file', data.public_file);
          }

          if (data.private_file) {
            formData.append('private_file', data.private_file);
          }

          payload.formData = true;
          payload.body = formData;
        }
        return payload;
      },
      invalidatesTags: ['Recipe'],
    }),
    deleteRecipe: builder.mutation<void, number>({
      query: (id) => ({
        url: `recipes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Recipe'],
    }),
    toggleRecipeFavorite: builder.mutation<{ recipeFavorited: boolean }, number>({
      query: (id) => ({
        url: `recipes/toggleFavorite/${id}`,
        method: 'GET',
      }),
    }),
    getFavoriteRecipe: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: () => 'recipes/favorites',
    }),
  }),
});

export const {
  useGetRecipesQuery,
  useGetRecipeQuery,
  useCreateRecipeMutation,
  useUpdateRecipeMutation,
  useDeleteRecipeMutation,
  useToggleRecipeFavoriteMutation,
  useGetFavoriteRecipeQuery
} = recipeApi;