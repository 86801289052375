import { User } from "../services/endpoints/people/user";
import { Enrollment } from "./enrollment.type";
import { timesheet_entries } from "./finance.types";

export interface Address {
  street: string;
  unit: string;
  city: string;
  state: string;
  zip_code: number;
}

export interface LocationInformation {
  id: number;
  facebook: string;
  instagram: string;

  who_we_are: string;
  franchise_owner_first_name: string;
  franchise_owner_last_name: string;
  franchise_owner_email: string;
  jobs_url: string;
  image_file_name: string;
  image_content_type: string;
  image_file_size: number;
  image_source: string;
  image_updated_at: Date;
}
export interface SFCLocation {
  addresses: Address[];
  phone_number: string;
  id: number;
  title: string;
  tax_id_number: string;
  scheduling_email: string;
  manager_id: number;
  manager?: User;
  latitude: number;
  longitude: number;
  zoom: number;
  hotline?: string;
  is_active: boolean;
  primary_city: string;
  invoicing_email: string;
  business_entity_name?: string;
  location_information: LocationInformation;

  location_images: File[];
  franchise_images: File[];
}

export interface SFCLocationResponse {
  addresses: Address[];
  phone_number: string;
  id: number;
  title: string;
  tax_id_number: string;
  scheduling_email: string;
  manager_id: number;
  manager?: User;
  latitude: number;
  longitude: number;
  zoom: number;
  hotline?: string;
  is_active: boolean;
  primary_city: string;
  invoicing_email: string;
  business_entity_name?: string;
  location_information: LocationInformation;

  location_images: LocationImage[];
  franchise_images: LocationImage[];
}

export interface LocationImage {
    id: number;
    image_file_name: string;
    image_content_type: string;
    image_file_size: number;
    image_updated_at: Date;
}

export interface TimesheetEntry {
  id: number;
  rate: number;
  reimbursement: number;
  total: number;
  override?: {
    id: number;
    override_reqeust: string;
    override_approved: boolean;
  };
  lesson: {
    id: number;
    date: string;
  };
  instructor: {
    id: number;
    first_name: string;
    last_name: string;
    full_name?: string;
  };
}

export interface SimpleSFCLocation {
  id: number;
  title: string;
  latitude: number;
  longitude: number;
}

export interface LocationResponse {
  location: SFCLocationResponse;
}

export interface LedgerResponse {
  ledger: LedgerData;
  title: string;
  claimedLedger?: LedgerData;
}

export interface LedgerParams {
  id: number;
  start?: string;
  finish?: string;
  course_types?: number[];
  course_statuses?: string[];
  by?: 'starts_on'|'ends_on';
}

export interface LedgerData {
  per_class_costs: PerClassCost[];
  per_session_costs: PerSessionCost[];
  receipts: SimpleReceipt[];
  timesheet_entries: TimesheetEntry[];
  enrollments: Enrollment[];
  refunds: Refund[];
  registrations: Registration[];
  rates: string | number;
  reimbursements: string | number;
  expenses: {
    receipts: number;
    per_session_costs: number;
    per_class_costs: number;
    instructor_payments?: number,
  };
  revenue: { 
    website_payments: number;
    onsite_enrollments: number;
    "additional_session-related_revenue": number;
    refunds: number;
  };
  sessions: {id: number}[];
  number_of_classes: number;
  number_of_children: number;
  net_profit: number;
  additional_payments_sum?: number;
}

export interface WeeklyReportResponse {
  data: WeeklyReportData[];
  title: string;
  franchise_name: string;
  territories: SFCTerritory[];
  royalty: number,
  brand_fee: number,
}

export interface WeeklyReportData {
  website_revenue: null|number;
  invoice_revenue: number;
  refunds: number;
  debits: number;
  total_revenue: number;
  net_revenue: number;
  royalty_fee: number;
  brand_fee: number;
  fees: number;
  total_ach: number;
  location: SimpleSFCLocation;
  start: string;
  end: string;
}

export interface SFCTerritory {
  id: number;
  title: string;
  location_id: number;
  location?: SimpleSFCLocation;
  zip_codes: SFCZipcodeSimple[];
  created_at: string;
}

export interface TerritoryResponse {
  territory: SFCTerritory;
}


export interface SFCZipcode {
  id: number;
  code: number;
  city: string;
  state: string;
  area: string;
  latitude?: number;
  longitude?: number;
  territory_id: number;
  tax_returns_filed: number;
  estimated_population: number;
  total_wages: number;
}

export interface SFCZipcodeSimple {
  id: number;
  code: number;
  territory_id: number;
}

export interface ZipcodeResponse {
  zipcode: SFCZipcode;
}

export interface VenueContact {
  id: number;
  venue_id: number;
  first_name: string;
  last_name: string;
  full_name?: string;
  email: string;
  title?: string;
  phone_number?: string;
  should_be_sent_roster: boolean;
  should_be_sent_invoices: boolean;
  should_be_sent_bulk_welcomes: boolean;
  should_be_sent_bulk_thanks: boolean;
}

export interface SFCVenue {
  course_count?: number;
  location?: SFCLocation;
  id: number;
  location_id: number;
  title: string;
  phone: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  has_facility_use_fee: boolean;
  facility_use_fee_payment_details: string;
  facility_user_fee_policy: string;
  venue_visible: boolean;
  venue_type: string;
  is_active: boolean;
  latitude: number;
  longitude: number;
  contace_first_name: string;
  contact_last_name: string;
  contact_email: string;
  ends_on: string;
  organizations?: SFCOrganization[];
  organization_ids: number[];
  notes?: string;
  contacts: VenueContact[];
}

export interface VenueResponse {
  venue: SFCVenue;
}

interface SimpleUser {
  id: number,
  first_name: string,
  last_name: string,
  full_name?: string,
}

export interface Registration {
    id: number,
    price: number,
    child: SimpleUser & { parent: SimpleUser}
}

interface SimpleReceipt {
  id: number,
  total: number|null,
  user: SimpleUser,
  store_name: string,
}

interface Refund {
  id: number,
  total: number|null,
  order: {
    id: number,
    parent: SimpleUser
  }
}

interface PerSessionCost {
  category: string,
  id: number,
  course_id: number,
  amount: number
}

interface PerClassCost {
  category: string,
  id: number,
  course_id: number,
  amount: number,
  total_for_session?: number;
}

export interface OrganizationContact {
  id: number;
  contactable_id: number;
  first_name: string;
  last_name: string;
  full_name?: string;
  email: string;
  title?: string;
  phone_number?: string;
  should_be_sent_roster: boolean;
  should_be_sent_invoices: boolean;
  should_be_sent_bulk_welcomes: boolean;
  should_be_sent_bulk_thanks: boolean;
}

export interface SFCOrganization {
  contacts: OrganizationContact[];
  created_at: string;
  location: SFCLocation;
  venues: SFCVenue[];
  id: number;
  location_id: number;
  name: string;
  notes: string;
}

export interface OrganizationResponse {
  organization: SFCOrganization;
}

export const convertContactImage = (locationInfo: LocationInformation): File => {
    // TODO: Get real blob
    const blob = new Blob([], { type: locationInfo.image_content_type });
    return new File([blob], locationInfo.image_file_name, { type: blob.type });
};

export const convertLocationImages = (locationImages: LocationImage[]): File[] => {
  return locationImages.map(img => {
    // TODO: Get real blob
    const blob = new Blob([], { type: img.image_content_type });
    return new File([blob], img.image_file_name, { type: blob.type });
  });
};

export const formatResponse = (response: SFCLocationResponse): SFCLocation => {
  return {
    addresses: response.addresses,
    phone_number: response.phone_number,
    id: response.id,
    title: response.title,
    tax_id_number: response.tax_id_number,
    scheduling_email: response.scheduling_email,
    manager_id: response.manager_id,
    manager: response.manager,
    latitude: response.latitude,
    longitude: response.longitude,
    zoom: response.zoom,
    hotline: response.hotline,
    is_active: response.is_active,
    primary_city: response.primary_city,
    invoicing_email: response.invoicing_email,
    business_entity_name: response.business_entity_name,
    location_information: {
      id: response.location_information?.id,
      facebook: response.location_information?.facebook,
      instagram: response.location_information?.instagram,
      who_we_are: response.location_information?.who_we_are,
      franchise_owner_first_name: response.location_information?.franchise_owner_first_name,
      franchise_owner_last_name: response.location_information?.franchise_owner_last_name,
      franchise_owner_email: response.location_information?.franchise_owner_email,
      jobs_url: response.location_information?.jobs_url,
      image_file_name: response.location_information?.image_file_name,
      image_content_type: response.location_information?.image_content_type,
      image_file_size: response.location_information?.image_file_size,
      image_source: response.location_information?.image_source,
      image_updated_at: response.location_information?.image_updated_at,
    },
    location_images: convertLocationImages(response.location_images), // Transformar LocationImage[] a File[]
    franchise_images: convertLocationImages(response.franchise_images), // Transformar LocationImage[] a File[]
  };
};